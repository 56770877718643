import { PageHeader, Socials } from "../Globals";

const pageTitle = "Author";
const pageDescription = "Learn more about Alvin & Louis' background and expertise";
// const pageDescription = "Learn more about Alvin's background and expertise";

function LRAuthor() {
  return (
    <div id="louis" className="row d-flex justify-content-center">
      <div className="col-8 col-md-4 me-md-5">
        <div className="d-flex justify-content-center">
          <img src="/img/rosenbergl.png" alt="Louis Rosenberg" className="col-10"/>
        </div>
        <h2 className="text-center pt-2">Louis Rosenberg</h2>
        {/* social */}
        <div className="container row col-12 text-center d-flex justify-content-center align-items-end">
          <a href="https://www.linkedin.com/in/louis-rosenberg/" className="p-2 me-4 col-auto">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="linkedin">
              <title>LinkedIn</title>
              <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
            </svg>
            &emsp;louis-rosenberg
          </a>
        </div>
      </div>
      <div className="col-11 col-lg-6 my-auto mx-1">
        {/* <div className="p-3 p-lg-5 bg-gray"> */}
          <p>Louis Rosenberg, PhD is an early pioneer of virtual and augmented reality and a longtime AI researcher. His work began over thirty years ago in VR labs at Stanford, NASA, and Air Force Research Laboratory where he developed the Virtual Fixtures platform, the first system enabling users to interact with a mixed reality of real and virtual objects. In 1993, he founded the early VR company Immersion Corporation and brought it public on Nasdaq. In 2004 he founded Outland Research, an early developer of augmented reality and spatial media technology acquired by Google. In 2014 he founded Unanimous AI, a company focused on amplifying the intelligence of networked human groups in pursuit of Collective Superintelligence. He received his PhD from Stanford University, was a tenured professor at California State University, and has been awarded over 300 patents for VR, AR, and AI technologies. He is currently CEO of Unanimous AI, the Chief Scientist of the Responsible Metaverse Alliance and writes often for major publications about the dangers of emerging technologies. His previous books include Arrival Mind, UPGRADE, and Monkey Room.</p>
        {/* </div> */}
      </div>
    </div>
  );
}

export default function Author() {
  return (
    <main>
      <div className="container pt-4">
        <div id="alvin" className="row d-flex justify-content-center">
          <PageHeader title={pageTitle} description={pageDescription}/>
          <div className="col-8 col-md-4 me-md-5">
            <img src="/img/agraylin.jpg" alt="Alvin Graylin"/>
            {/* temp */}<h2 className="text-center pt-2">Alvin Wang Graylin</h2>
            <Socials hide="d-none" />
          </div>
          <div className="col-11 col-lg-6 my-auto mx-1">
            {/* <div className="p-3 p-lg-5 bg-gray"> */}
              <p>Alvin Wang Graylin (汪丛青) is a globally recognized tech leader, author, investor, and serial entrepreneur with over 30 years of innovation across AI, XR, cybersecurity, and semiconductors. He currently serves as Chairman of the Virtual World Society, Vice-Chair of the Industry of VR Alliance, and is a Digital Fellow at Stanford's Digital Economy Lab (within HAI). Previously, he was Global VP and China President at HTC, where he led the company’s XR business for nearly a decade.</p>
              <p>Graylin is the author of Our Next Reality (Hachette Books), which explores how the convergence of AI and XR will transform the future of society. He has founded four venture-backed startups in conversational AI search, mobile social, mobile AR, and AI-driven big data analytics, spanning both the U.S. and China markets. As an active investor, he has backed over 100 startups and helped launch HTC’s ViveX Global VR accelerator and SOSV’s mobile internet incubator.</p>
              <p>His career includes exec roles at Intel, IBM, Trend Micro, and WatchGuard. A sought-after global keynote speaker, Graylin is frequently featured in international media as a thought leader on AI, immersive computing, technology policy, entrepreneurship, and the Chinese tech landscape.</p>
              <p>Graylin holds an M.S. in Computer Science specializing in AI from MIT, an M.S. in Business from MIT’s Sloan School of Management with emphasis on entrepreneurship and operations, and graduated top of his class with a B.S. in Electrical Engineering from the University of Washington, focusing on VR, AI, and CPU architecture. Fluent in English, Mandarin, and Cantonese, Graylin’s bicultural background, Eurasian heritage and cross-border leadership experience uniquely position him at the intersection of technology, business, and global impact.</p>
            {/* </div> */}
          </div>
        </div>
        <LRAuthor />
      </div>
    </main>
  );
}
